import { BehaviorSubject } from 'rxjs';
import { Permissions } from '@/types/permissionsTypes';
import { httpRequest } from '@/functions/httpRequest';

export const permissionsState = new BehaviorSubject<Permissions>({});

export async function getPermissions() {
  try {
    const facets = await httpRequest('get', 'subjects/facets', {}, {
      version: '2',
      area: 'iam',
    });

    const permissionsGranted = {};
    const arr = [];
  
    facets.permissions.forEach(permission => {
      arr.push(permission.resource);

      permissionsGranted[permission.resource] = {
        create: permission.actions.includes('C'),
        read: permission.actions.includes('R'),
        update: permission.actions.includes('U'),
        delete: permission.actions.includes('D'),
      };
    });

    permissionsState.next(permissionsGranted);
  } catch(e) {
    console.error(e);
  }
}