import storage from './manageStorage';
import { httpRequest } from './httpRequest'; 

export const logout = async (): Promise<void> => {
  try {
    storage.clear();
    deleteCookie('token');
    const logoutUrl = process.env.BUREAU_URL.replace(/\/v\d+/, '/logout');
    await httpRequest('post', logoutUrl);
  } catch (error) {
    console.error(error);
  }
};

export const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export default {
  logout,
  deleteCookie
};