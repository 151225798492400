import { BehaviorSubject } from 'rxjs';
import { FeatureFlags } from '@/types/permissionsTypes';
import { httpRequest } from '@/functions/httpRequest';

export const featureFlagsState = new BehaviorSubject({
  data: <FeatureFlags>{},
  error: false,
  pending: false,
});

export const hasFeatureFlags = new BehaviorSubject(null);

export async function getFeatureFlags() {
  try {
    const features = await httpRequest('get', 'tenants/current/features', {}, { area: 'iam', version: '2', });
    hasFeatureFlags.next(true);
    return featureFlagsState.next({
      data: features,
      error: false,
      pending: false,
    });
  } catch(e) {
    hasFeatureFlags.next(false);
    console.error('e', e);
  }
}