// Anything exported from this file is importable by other in-browser modules.
// Functions
import abbr from './functions/abbr';
import address from './functions/address';
import auth from './functions/auth';
import {
  deepClone,
  isEqualObjects,
  getKeyByValue,
  searchObject
} from './functions/common';
import currency from './functions/currency';
import dates from './functions/datetime';
import { debounce } from './functions/debounce';
import escapeSpecialCharacters from './functions/escapeSpecialCharacters';
import { gqlRequest } from './functions/gqlRequest';
import { httpFileRequest } from './functions/httpFileRequest';
import { httpRequest } from './functions/httpRequest';
import pluralise from './functions/pluralise';
import { setErrors } from './functions/setErrors';
import storage from './functions/manageStorage';
import { t } from './functions/translate';
import { uuid } from './functions/uuid';

// Subjects
import { alertsState, setAlerts, removeAlertByIndex } from './subjects/alerts';
import { breadcrumbsState, updateBreadcrumb } from './subjects/breadcrumbs';
import { currencyState, getCurrency } from './subjects/currency';
import { customerModeState, toggleCustomerMode } from './subjects/customerMode';
import { mailState, getAccountInfo, getMail, sendMail } from './subjects/email';
import { modalQueueState, updateQueue, popMessageFromStack } from './subjects/modalQueue';
import { notificationDashboardState, updateWidgetToAdd } from './subjects/notificationDashboardState';
import { permissionsState, getPermissions } from './subjects/permissions';
import { featureFlagsState, getFeatureFlags } from './subjects/features';
import { searchBarState, showSearchBar, hideSearchBar, setSearchBarType } from './subjects/searchBar';
import { tenantState, getTenantDetails } from './subjects/tenant';
import { translationsState, getTranslations } from './subjects/translations';
import { isLoggedInState, userDetailsState, getUserDetails } from './subjects/userDetails';

window['FE_UTILS_VERSION'] = process.env.FE_UTILS_VERSION;

export {
  // RxJS Subjects
  alertsState,
  breadcrumbsState,
  currencyState,
  customerModeState,
  isLoggedInState,
  mailState,
  modalQueueState,
  notificationDashboardState,
  permissionsState,
  searchBarState,
  tenantState,
  translationsState,
  userDetailsState,
  featureFlagsState,

  // RxJS Functions
  getAccountInfo,
  getCurrency,
  getMail,
  getPermissions,
  getTenantDetails,
  getTranslations,
  getUserDetails,
  getFeatureFlags,
  hideSearchBar,
  popMessageFromStack,
  removeAlertByIndex,
  sendMail,
  setAlerts,
  setSearchBarType,
  showSearchBar,
  toggleCustomerMode,
  updateBreadcrumb,
  updateQueue,
  updateWidgetToAdd,

  // Util Functions
  abbr,
  address,
  auth,
  currency,
  dates,
  debounce,
  deepClone,
  escapeSpecialCharacters,
  gqlRequest,
  httpFileRequest,
  httpRequest,
  isEqualObjects,
  getKeyByValue,
  pluralise,
  searchObject,
  setErrors,
  storage,
  t,
  uuid,
};